import React from "react";
// import { Link } from "gatsby";

import Layout from "../layouts/layout";
// import Image from "../components/image";
import SEO from "../components/seo";

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />

  </Layout>
);

export default ContactPage;
